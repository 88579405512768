// stores/consent.js
import { defineStore } from "pinia";
import { ref } from "vue";
import { useFetch, useRuntimeConfig } from "#app";

export const useConsentStore = defineStore("consent", () => {
  const cookieData = ref(null);
  const error = ref(null);

  // Getting baseApi from public runtime config
  const config = useRuntimeConfig();
  const baseApi = config.public.baseApi;

  // Getting locale from i18n
  const { locale } = useI18n();

  const fetchCookie = async () => {
    const apiUrl = `${baseApi}/${locale.value}/consent/cookies`;

    const { data, error: fetchError } = await useFetch(apiUrl);
    if (!fetchError.value) {
      cookieData.value = data.value.data;
    } else {
      console.error("Error fetching cookie data:", fetchError.value);
      error.value = fetchError.value;
    }
  };

  return {
    cookieData,
    error,
    fetchCookie,
  };
});
