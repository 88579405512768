<template>
    <LayoutsHeader />

    <main class="main-content">

        <slot />

    </main>

    <LayoutsFooter />

    <LayoutsCookieDefault />

</template>

<script setup type="ts">
const { locale } = useI18n();
const config = useRuntimeConfig();
const appUrl = config.public.baseApi;
const storageUrl = config.public.storageUrl;

useHead({

    htmlAttrs: {
        lang: locale
    },
    link: [
        {
            rel: "shortcut icon",
            href: `${storageUrl}/storage/favicon.png`
        },
    ]
});

onMounted(() => {

    nextTick(function () {

        

        window.addEventListener("scroll", function () {

            var navbar = document.querySelector(".sub-menu");

            if (navbar != null) {

                var nav_classes = navbar.classList

                if (document.documentElement.scrollTop >= 100) {

                    if (nav_classes.contains("shrink") === false) {
                        nav_classes.toggle("shrink");
                    }
                }
                else {

                    if (nav_classes.contains("shrink") === true) {
                        nav_classes.toggle("shrink");
                    }
                }
            }
        })

    })

    // const script = document.createElement('script');
    // script.src = 'https://lumos.shareinvestorthailand.ai/widgets/lumos-plugin.js';
    // script.setAttribute('data-source', 'btg');
    // document.body.appendChild(script);


    // const style = document.createElement('link');
    // style.href = 'https://lumos.shareinvestorthailand.ai/widgets/css/chat.css';
    // style.setAttribute('rel', 'stylesheet');
    // document.body.prepend(style);

});


</script>


<style>

@import '/assets/fonts/font-icon.css';
@import '/assets/scss/index.scss';

@import '/assets/vendor/bootstrap4/css/bootstrap.css';
@import '/assets/vendor/fontawesome/css/all.css';
@import '/assets/vendor/animate/animate.css';
@import '/assets/vendor/lity/lity.min.css';
@import '/assets/vendor/owl-carousel2/assets/owl.carousel.css';

body {
    padding: 0;
    margin: 0;
}

</style>
