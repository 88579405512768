<template>
  <header class="header fixed-top" id="header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-10 offset-xl-1">
          <div class="row no-gutters">
            <div class="col-8 col-sm-4 col-xl-2">
              <div class="header_logo">
                <a :href="localePath('/home')">
                  <NuxtImg loading="lazy" src="/static/images/logo.png" alt="" class="img-fluid" />
                </a>
              </div>
            </div>

            <div class="col-4 col-sm-8 col-xl-10 header_menu">

              <div id="menu">
                
                <LayoutsMenusMain />

              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </header>
</template>

<script></script>

<style scoped></style>
