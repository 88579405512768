// stores/cookieWow.js
import { defineStore } from "pinia";
import { useFetch, useRuntimeConfig } from "#app";

export const useCookieWowStore = defineStore("cookieWow", {
  state: () => ({
    cookieWowId: null,
    gtm: null,
    isLoading: false,
    isShow: false,
    error: null,
  }),
  actions: {
    async fetchCookieWowId() {
      this.isLoading = true;
      this.error = null;

      const config = useRuntimeConfig();
      const baseApi = config.public.baseApi;

      try {
        // Fetch the CookieWow ID from the API
        const { data, error: fetchError } = await useFetch(
          `${baseApi}/en/consent/cookiewow`
        );

        if (fetchError.value) {
          throw new Error("Error fetching CookieWow ID");
        }

        this.cookieWowId = data.value.data.cookie_wow;
        this.gtm = data.value.data.gtm;
      } catch (err) {
        this.error =
          err.message || "An error occurred while fetching the CookieWow ID.";
      } finally {
        this.isLoading = false;
      }
    },
    injectCookieWowScript(id, gtm) {
      // Function to load script and return a promise
      const loadScript = (src, isConfig = false, id = "", attributes = {}) => {
        return new Promise((resolve, reject) => {
          const scriptElement = document.createElement("script");
          scriptElement.type = "text/javascript";
          scriptElement.src = src;
          scriptElement.nonce = "";

          if (id) {
            scriptElement.id = id;
          }

          if (isConfig) {
            scriptElement.setAttribute("data-cwcid", attributes.cwcid);
          }

          scriptElement.onload = resolve; // Resolve when script is loaded
          scriptElement.onerror = reject; // Reject if there is an error loading the script

          document.head.prepend(scriptElement);
        });
      };

      // Inject CookieWow and GTM scripts sequentially
      loadScript("https://cookiecdn.com/cwc.js")
        .then(() => {
          // After the main CookieWow script is loaded, inject the configuration script
          return loadScript(
            `https://cookiecdn.com/configs/${id}`,
            true,
            "cookieWow",
            { cwcid: id }
          );
        })
        .then(() => {
          // Once CookieWow config is loaded, inject GTM script
          const script = document.createElement("script");
          script.id = "gtm-script";
          script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtm}');`;
          document.head.prepend(script);

          // Inject noscript iframe for GTM
          const iframe = document.createElement("noscript");
          iframe.id = "gtm-noscript";
          iframe.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.prepend(iframe);
        })
        .catch((error) => {
          console.error("Error loading scripts: ", error);
        });
    },
  },
});
