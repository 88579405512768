<template>
    <nav class="navbar navbar-toggleable-lg navbar-light">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto" id="main-menu" v-if="getMenus && getMenus.length">
          <li class="nav-item" v-for="(item, i) in getMenus[0]?.children" :key="i">
            <NuxtLink :to="localePath(item.url)" prefetch-on="interaction">
              {{ item.name }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useFetch, useRuntimeConfig } from '#app';
  import { useLocalStorage } from '@/composables/useLocalStorage';  // นำเข้า useLocalStorage
  
  const router = useRouter();

  // สร้าง state เพื่อเก็บข้อมูลเมนู
  const getMenus = ref([]);
  
  // ใช้ localePath สำหรับ nuxt-link
  const localePath = useLocalePath();
  
  // ดึงค่า baseApi จาก nuxt.config.ts
  const config = useRuntimeConfig();
  const baseApi = config.public.baseApi;
  
  // ดึง locale จาก i18n
  const { locale } = useI18n();
  
  // ใช้ useLocalStorage
  const { setItemWithExpiry, getItemWithExpiry } = useLocalStorage();

  router.beforeEach(() => {
    const navbarSupportedContent = document.getElementById('navbarSupportedContent');

    if (navbarSupportedContent) {
        navbarSupportedContent.classList.remove('show');
    }
})
  
  // ใช้ onMounted เพื่อดึงข้อมูลจาก API เมื่อ component ถูกสร้างขึ้น
  onMounted(async () => {
    const cachedMenu = getItemWithExpiry('main-menu-v2');

    if (cachedMenu) {
      getMenus.value = cachedMenu;

    } else {

      const apiUrl = `${baseApi}/${locale.value}/menus/main`;
      const { data, error } = await useFetch(apiUrl);

      if (!error.value) {
        // เก็บข้อมูลเมนูใน state
        getMenus.value = data.value.data;
  
        // เก็บข้อมูลเมนูลง localStorage (เช่น 1 วัน = 86400000 มิลลิวินาที)
        setItemWithExpiry('main-menu-v2', data.value.data, 60);

      } else {
        console.error('Error fetching menu data:', error.value);
      }
    }
  });
  </script>
  

  <style scoped lang="scss">

  .header {
      #main-menu {
          .nav-item {
              .router-link-active {

                  color: #1c1c1c;
                  &:after {
                      content: "";
                      opacity: 1 !important;
                      visibility: visible !important;
                      height: 1px;
                  }
              }
              a{
                &:focus,
                &:hover{
                    text-decoration: none;
                  }
              }
          }
      }
  }
  
  </style>