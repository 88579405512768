<template>
    <template v-if="getMenus && getMenus.length">
        <template v-for="(item, i) in getMenus[0]?.children" :key="i">
            | <NuxtLink :to="localePath(item.url)" prefetch-on="interaction">
              {{ item.name }}
            </NuxtLink>
        </template>
    </template>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useFetch, useRuntimeConfig } from '#app';
  import { useLocalStorage } from '@/composables/useLocalStorage';  // นำเข้า useLocalStorage
  
  // สร้าง state เพื่อเก็บข้อมูลเมนู
  const getMenus = ref([]);
  
  // ใช้ localePath สำหรับ nuxt-link
  const localePath = useLocalePath();
  
  // ดึงค่า baseApi จาก nuxt.config.ts
  const config = useRuntimeConfig();
  const baseApi = config.public.baseApi;
  
  // ดึง locale จาก i18n
  const { locale } = useI18n();
  
  // ใช้ useLocalStorage
  const { setItemWithExpiry, getItemWithExpiry } = useLocalStorage();
  
  // ใช้ onMounted เพื่อดึงข้อมูลจาก API เมื่อ component ถูกสร้างขึ้น
  onMounted(async () => {
    const cachedMenu = getItemWithExpiry('footer-menu');
    if (cachedMenu) {
      getMenus.value = cachedMenu;
    } else {
      const apiUrl = `${baseApi}/${locale.value}/menus/footer`;
      const { data, error } = await useFetch(apiUrl);
      if (!error.value) {
        // เก็บข้อมูลเมนูใน state
        getMenus.value = data.value.data;
  
        // เก็บข้อมูลเมนูลง localStorage (เช่น 1 วัน = 86400000 มิลลิวินาที)
        setItemWithExpiry('footer-menu', data.value.data, 86400000);
      } else {
        console.error('Error fetching menu data:', error.value);
      }
    }
  });
  </script>

<style lang="scss" scoped>

a{
    color: #fff;
}
</style>
