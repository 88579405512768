<template>
  <footer class="footer">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12 col-xl-10 offset-xl-1">
                    <div class="row footer__content">

                        <div class="col-12 col-sm-4 col-xl-4">

                            <div class="content__contact">
                                <div class="media">
                                    <i class="d-flex mr-4 icon icon-phone"></i>
                                    <div class="media-body">
                                        <h6 class="mt-0">{{ translate('contact.us') }}</h6>
                                        <address>
                                            <span>{{ translate('tel') }}: {{ translate('tel.number') }}</span><br>
                                            <span>{{ translate('fax') }}: {{ translate('fax.number') }}</span><br>
                                            <span>
                                                {{ translate('email') }}:
                                                
                                                <span v-html="translate('email.detail')"></span>
                                            </span>
                                        </address>
                                    </div>
                                </div>

                                <div class="media">
                                    <i class="d-flex mr-4 icon icon-pin"></i>
                                    <div class="media-body">
                                        <h6 class="mt-0">{{ translate('address') }}</h6>
                                        <address>
                                            <span>{{ translate('address.detail01') }}</span><br>
                                            <span>{{ translate('address.detail02') }}</span><br>
                                            <span v-html="translate('address.detail03')"></span><br>
                                            <span>{{ translate('address.detail04') }}</span>
                                        </address>
                                    </div>
                                </div>
                            </div>

                        </div>

                       <div class="col-12 col-sm-5 col-xl-4">

                            <div class="content__join">
                                <p class="mt-0 text-white text-uppercase font-weight-bold">{{ translate('join') }}</p>
                                <p class="display-6 text-white font-normal mb-0">{{ translate('join.detail01') }}</p>
                                <p class="display-6 text-white font-normal">
                                    <span v-html="translate('join.detail02')"></span>
                                </p>

                                <a :href="localePath('/career')" class="btn btn-outline text-white">
                                    {{ translate('join.now') }}
                                </a>

                            </div>

                        </div>

                         

                        <div class="col-12 col-sm-3 col-xl-3 offset-xl-1">

                            <div class="content__office">
                                <h6 class="mt-0 text-white">{{ translate('office.hour') }}</h6>
                                <p>
                                    <span>{{ translate('office.hour.detail01') }}</span><br>
                                    <span>{{ translate('office.hour.detail02') }} </span><br>
                                </p>

                                <h6 class="mt-0 text-white">{{ translate('follow') }}</h6>
                                <ul class="list-inline">
                                    <li class="list-inline-item list-inline-item--facebook">
                                        <a :href="translate('facebook.url')" target="_blank">
                                            <IconBrandFacebook :size="25" stroke-width="1" />
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a :href="translate('instagram.url')" target="_blank">
                                            <IconBrandInstagram :size="25" stroke-width="1" />
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a :href="translate('youtube.url')" target="_blank">
                                            <IconBrandYoutube :size="25" stroke-width="1" />
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a :href="translate('twitter.url')" target="_blank">
                                            <IconBrandX :size="25" stroke-width="1" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="footer__copy">
            <p>
                <span v-html="translate('copyright')"></span>
                <LayoutsMenusFooter />
            </p>
        </div>

    </footer>
</template>

<script setup>
import { onMounted } from 'vue'
import { useTranslationStore } from '@/stores/translations'
import { IconBrandFacebook, IconBrandYoutube, IconBrandInstagram, IconBrandX } from '@tabler/icons-vue';

// ดึง store สำหรับการแปล
const translationStore = useTranslationStore()

// ดึงข้อมูลการแปลเมื่อ component ถูก mount
callOnce(async () => {
  await translationStore.fetchTranslations()
})

// สร้างฟังก์ชันสำหรับการแปล
const translate = translationStore.translate
</script>

<style lang="scss" scoped>
.list-inline-item{

    a{
        display: flex;
        background-color: #1c1c1c;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        &:hover{
            background-color: #b3b0b2;
            opacity: 1;
            svg{
                color: #1c1c1c;
            }
        }

        svg{
            color: #b3b0b2;
        }
    }
    &.list-inline-item--facebook{
        position: relative;
    }


}

.btn-outline{
    border-radius: 0;
    border: 1px solid #fff;
}

</style>