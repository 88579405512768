<template>
  <div id="cookie" v-if="!cookieWow && data">
    <div v-html="data?.setting"></div>
  </div>
</template>

<script setup>

import { ref, computed, watch, onMounted } from 'vue';
import { useConsentStore } from '@/stores/consent';

const props = defineProps({
  cookieWow: {
    type: Boolean,
    required: true,
  },
});

// Utility functions to set and get cookies
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=Strict;Secure;';
}

function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// Use the Consent Store
const store = useConsentStore();
const data = computed(() => store.cookieData);
const error = computed(() => store.error);

// Reactive state variables
const showSettings = ref(false);
const showCookieBar = ref(true);
const acceptExpire = ref('1d');
const rejectExpire = ref('1d');
const enableCookiePerformance = ref(false);
const cookiePerformance = ref(false);
const cookiePolicy = ref(false);

// Methods
function init() {
  
  prependScript();

  showCookieBar.value = getCookie('cookie-policy') ? 0 : 1;
  cookiePolicy.value = getCookie('cookie-policy') ? 1 : 0;

  acceptExpire.value = data.value?.accept;
  rejectExpire.value = data.value?.reject;

  cookiePerformance.value = getCookiePerformance();
  enableCookiePerformance.value = cookiePerformance.value;

  if (cookiePerformance.value && !getCookie('cookie-policy')) {
    setCookie('cookie-performance', true, acceptExpire.value);
  }

  setupEventListeners();
}

function getCookiePerformance() {
  if (getCookie('cookie-performance')) {
    return getCookie('cookie-performance');
  } else {
    return data.value.enable_by_default;
  }
}

function toggleSetting() {
  showSettings.value = !showSettings.value;
}

function toggleCookiePerformance() {
  enableCookiePerformance.value = !enableCookiePerformance.value;
}

function acceptCookie() {
  enableCookiePerformance.value = true;
  onSave(acceptExpire.value);
}

function rejectCookie() {
  enableCookiePerformance.value = false;
  onSave(rejectExpire.value);
}

function onSave(expire) {
  showSettings.value = false;
  showCookieBar.value = false;

  if (typeof expire === 'undefined' || expire === '') {
    expire = acceptExpire.value;
  }

  // Define 3rd party cookies
  cookiePerformance.value = enableCookiePerformance.value;

  if (cookiePerformance.value) {
    setCookie('cookie-performance', true, acceptExpire.value);
  } else {
    removeAnalyticCookies();
  }

  // Necessary cookies
  setCookie('cookie-policy', true, expire);
}

function prependScript() {
  const tracking = data.value.gtm;

  if (tracking !== undefined && tracking !== '') {
    try {
      
      const consent = document.createElement('script');
      consent.id = 'consent-script';
      consent.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){ dataLayer.push(arguments); }
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
        })
      `;

      const script = document.createElement('script');
      script.id = 'gtm-script';
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${tracking}');`;

      const iframe = document.createElement('noscript');

      iframe.id = 'gtm-noscript';
      iframe.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tracking}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.prepend(consent);
      document.head.prepend(script);
      document.body.prepend(iframe);

    } catch (error) {
      // console.log(error);
    }
  }
}

function googleConsentMode() {
  const consent = document.createElement('script');
  consent.id = 'consent-update';
  consent.innerHTML = `
    gtag('consent', 'update', {
      'ad_storage': 'granted',
    })
    gtag('consent', 'update', {
      'analytics_storage': 'granted',
    })
  `;
  document.head.append(consent);
}

function removeAnalyticCookies() {
  setCookie('cookie-performance', false, 0);

  const tracking = data.value.gtm;

  if (tracking !== undefined && tracking !== '') {
    const script = document.getElementById('gtm-script');
    const noscript = document.getElementById('gtm-noscript');
    const consent = document.getElementById('consent-script');

    try {
      if (script) script.remove();
      if (noscript) noscript.remove();
      if (consent) consent.remove();
    } catch (error) {
      // console.log(error);
    }
  }
}

function setupEventListeners() {
  const cookieClose = document.getElementsByClassName('cookie-setting__close')[0];
  const cookieButton = document.getElementsByClassName('cookie-button')[0];
  const cookieSetting = document.getElementsByClassName('btn-setting')[0];
  const cookieAccept = document.getElementsByClassName('btn-accept')[0];
  const cookieAcceptAll = document.getElementsByClassName('btn-accept-all')[0];
  const cookieSave = document.getElementsByClassName('btn-save')[0];
  const notice = document.getElementsByClassName('cookie-notice')[0];

  if (cookieClose) {
    cookieClose.addEventListener('click', () => {
      toggleSetting();
      if (notice) {
        notice.classList.add('cookie-notice--show');
      }
    });
  }

  if (cookieButton) {
    cookieButton.addEventListener('click', toggleCookiePerformance);
  }

  if (cookieSetting) {
    cookieSetting.addEventListener('click', toggleSetting);
  }

  if (cookieAccept) {
    cookieAccept.addEventListener('click', acceptCookie);
  }

  if (cookieAcceptAll) {
    cookieAcceptAll.addEventListener('click', acceptCookie);
  }

  if (cookieSave) {
    cookieSave.addEventListener('click', onSave);
  }
}

// Watchers
watch(cookiePerformance, (value) => {

  const cookieNotice = document.getElementsByClassName('cookie-notice')[0];
  const cookieSetting = document.getElementsByClassName('cookie-setting')[0];
  const cookieButton = document.getElementsByClassName('cookie-notice')[0];

  if (value) {
    googleConsentMode();
  }

  if (cookiePolicy.value && cookieNotice) {
    cookieNotice.classList.remove('cookie-notice--show');
  }

  if (value && cookieSetting && cookieButton) {
    cookieSetting.classList.remove('cookie-setting--show');
    cookieButton.classList.remove('cookie--backdrop');
  }
});

watch(showSettings, (value) => {
  const cookie = document.getElementsByClassName('cookie')[0];
  const setting = document.getElementsByClassName('cookie-setting')[0];
  const notice = document.getElementsByClassName('cookie-notice')[0];

  if (value) {
    cookie.classList.add('cookie--backdrop');
    setting.classList.add('cookie-setting--show');
    notice.classList.remove('cookie-notice--show');
  } else {
    cookie.classList.remove('cookie--backdrop');
    setting.classList.remove('cookie-setting--show');
  }
});

watch(enableCookiePerformance, (value) => {
  const cookieButton = document.getElementsByClassName('cookie-button')[0];

  if (cookieButton) {
    if (value) {
      cookieButton.classList.add('cookie-button--active');
    } else {
      cookieButton.classList.remove('cookie-button--active');
    }
  }
});

watch(showCookieBar, (value) => {
  const notice = document.getElementsByClassName('cookie-notice')[0];
  if (notice) {
    if (value) {
      notice.classList.add('cookie-notice--show');
    } else {
      notice.classList.remove('cookie-notice--show');
    }
  }
});

watch(cookiePolicy, (value) => {
  if (value) {
    const cookie = document.getElementById('cookie');
    if (cookie !== undefined) {
      cookie.remove();
    }
  }
});

// Mount the component
onMounted(async () => {

  await store.fetchCookie();

  if (data.value !== undefined && !props.cookieWow) {
    init();
  }
});

</script>

<style lang="scss">
@import '/assets/scss/cookie.scss';
</style>
